import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title=" " />
    <div class="flex items-center justify-center h-screen bg-gray-200">
      <div class="container">
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div class="text-center">
            {/* <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              We <span class="text-indigo-600">Solve</span> It
            </h2> */}
            <StaticImage src="../images/wsi_logo_hor.png" alt="Logo We Solve It" />
            <h3 class='text-xl md:text-3xl mt-10'>Aguarde</h3>
            <p class="text-md md:text-xl mt-10">Estamos reformulando nosso portifólio de serviço e em breve teremos um novo site para você.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
